<template>
  <div>
    <b-card class="filter-body">
      <b-row>
        <b-col lg="3" md="3" sm="12" xs="12">
          <v-select
            multiple
            v-model="projectSelected"
            :options="projectOptions"
            placeholder="All Projects"
          />
        </b-col>

        <b-col lg="3" md="3" sm="12" xs="12">
          <date-picker
            v-model="dateRange"
            lang="en"
            type="date"
            range
            format="MMMM DD, YYYY"
            placeholder="Select Date Range"
            range-separator=" - "
            class="custom-datepicker-filter"
            calendar-class="my-datepicker_calendar"
          >
          </date-picker>
        </b-col>

        <b-col
          lg="3"
          md="3"
          sm="12"
          xs="12"
          v-if="
            userData.default_region_id ==
              'f06c8247-3b89-4ab5-8bb7-3734bf931c53' &&
            (userData.role_name == 'GLOBAL_ADMIN' ||
              userData.role_name == 'ACCOUNT_MANAGER')
          "
        >
          <b-form-select
            v-model="selectedCurrency"
            :options="currencyOptions"
            value-field="code"
            text-field="code"
          />
        </b-col>

        <b-col>
          <b-row class="d-flex justify-content-end">
            <b-col class="p-1 px-md-1 p-md-0 pl-md-0 col-auto">
              <b-button variant="primary" class="mr-2" @click="filterDate">
                <div class="d-flex align-items-center">
                  <b-spinner
                    small
                    label="Text Centered"
                    v-if="isLoading"
                  ></b-spinner>
                  <span class="button-title">Filter</span>
                </div>
              </b-button>

              <b-button
                variant="primary"
                @click="clear"
                :disabled="projectSelected == '' && dateRange == ''"
              >
                <div class="d-flex align-items-center">
                  <b-spinner
                    small
                    label="Text Centered"
                    v-if="isLoad"
                  ></b-spinner>
                  <span class="button-title">Clear</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <b-row cols="2">
        <b-col
          cols="12"
          lg="6"
          md="12"
          sm="12"
          xs="12"
          id="gcp-services-table-card-col"
        >
          <b-card class="card-height h-100" id="gcp-services-table-card">
            <loading
              :active.sync="loading"
              :can-cancel="true"
              :is-full-page="false"
              color="#4285f4"
              loader="dots"
            />
            <!-- table -->
            <b-table
              hover
              v-if="!loading"
              :items="tableItems"
              :fields="tableColumns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
              :per-page="perPage"
              :current-page="currentPage"
              empty-text="No matching records found"
              id="gcp-services"
              class="mt-1"
              @head-clicked="onHeadClicked"
              responsive
            >
              <template #head()="data">
                <span v-if="data.label == 'Discount Type'">
                  {{ data.label }}
                </span>
                <span v-else class="d-flex justify-content-center">{{
                  data.label
                }}</span>
              </template>

              <template #cell(discount_type)="data">
                <div class="row" v-if="data.item">
                  <b-form-checkbox
                    v-model="data.item.isChecked"
                    @change="checked(data.item)"
                  >
                  </b-form-checkbox>
                  <span
                    v-b-tooltip.hover
                    :title="data.item.name"
                    id="circle"
                    :style="{ backgroundColor: data.item.color }"
                  >
                  </span>
                  <span v-b-tooltip.hover :title="data.item.name">
                    {{
                      data.item.name.length > 30
                        ? data.item.name.substring(0, 25) + "..."
                        : data.item.name
                    }}
                  </span>
                </div>
              </template>
              <template #cell(amount)="data">
                <span class="d-flex justify-content-center">{{
                  data.item.amount.toFixed(2) | toCurrency(data.item.currency)
                }}</span>
              </template>
            </b-table>

            <!-- Pagination -->
            <b-card v-show="!loading" class="border-top border-bottom">
              <b-row>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  lg="7"
                  md="7"
                  sm="7"
                  xs="7"
                  v-show="!loading"
                  class="d-flex align-items-center justify-content-start"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-number
                    last-number
                    limit="3"
                    prev-class="prev-item"
                    next-class="next-item"
                    aria-controls="my-table"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>

                <b-col
                  cols="12"
                  lg="5"
                  md="5"
                  sm="5"
                  xs="5"
                  class="d-flex align-items-center justify-content-end"
                  style="font-weight: bold"
                  v-if="totalCost"
                >
                  TotalDiscounts:
                  {{
                    totalCost.total.toFixed(2) | toCurrency(totalCost.currency)
                  }}
                </b-col>
              </b-row>
            </b-card>
          </b-card>
        </b-col>
        <b-col cols="12" lg="6" md="12" sm="12" xs="12" id="linear-chart-view">
          <b-card class="card-height h-100">
            <loading
              :active.sync="loading"
              :can-cancel="true"
              :is-full-page="false"
              color="#4285f4"
              loader="dots"
            />
            <!-- chart -->
            <LineChartGenerator
              v-show="!loading"
              :chart-options="chartOptions"
              :chart-data="chartData"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker/index.esm";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  BCard,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BTable,
  BPagination,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import axios from "@axios";
import vSelect from "vue-select";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
export default {
  components: {
    DatePicker,
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
    BSpinner,
    BTable,
    BPagination,
    BFormCheckbox,
    LineChartGenerator,
    Loading,
    VBTooltip,
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      selectedCurrency: null,
      currencyOptions: [],
      dateRange: [],
      projectSelected: [],
      projectOptions: [],
      loading: false,
      isLoading: false,
      isLoad: false,
      startDate: "",
      endDate: "",
      sortBy: "",
      isSortDirDesc: true,
      totalCost: 0,
      tableItems: [],
      currentPage: 1,
      perPage: 10,
      checkedServices: [],
      finalChartData: {
        labels: [],
        datasets: [],
      },
      tableColumns: [
        { key: "discount_type", sortable: false },
        { key: "amount", sortable: true },
      ],
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            time: {
              unit: "day",
            },
            grid: {
              tickColor: "white",
            },
            title: {
              display: true,
              text: "Dates of month",
              font: {
                size: 14,
                weight: "bold",
                family: "Montserrat, Helvetica, Arial, serif",
              },
            },
            ticks: {},
          },
          y: {
            grid: {
              tickColor: "white",
            },
            title: {
              display: true,
              text: "Discount",
              font: {
                size: 14,
                weight: "bold",
                family: "Montserrat, Helvetica, Arial, serif",
              },
            },
            ticks: {
              callback: function (value) {
                if (store.state.app.currencyType == "USD") {
                  return "$ " + value;
                } else if (store.state.app.currencyType == "GBP") {
                  return "£ " + value;
                } else if (store.state.app.currencyType == "EUR") {
                  return "€ " + value;
                }
              },
            },
          },
        },
      },
    };
  },
  watch: {
    selectedCurrency(newValue) {
      if (newValue) {
        store.commit("app/CURRENCY_TYPE", newValue);
      }
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.projectSelected = "";
        this.dateRange = "";
        this.dateRange = [];
        this.currentPage = 1;
        this.selectedCurrency = this.userData.deafult_currency_code;
        this.getCurrentMonth();
        this.getDiscountReport();
        this.getBillingAccountIds();
        if (
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
        ) {
          const regionCurrencyData = JSON.parse(
            localStorage.getItem("regionCurrencyData")
          );
          for (const [key, value] of Object.entries(
            regionCurrencyData.regions_details
          )) {
            this.currencyOptions = value.currencies;
          }
        }
      },
    },
    "$store.state.appConfig.layout.skin": {
      handler() {
        if (store.state.appConfig.layout.skin == "dark") {
          this.chartOptions.scales.x.title.color = "#FFFFFF";
          this.chartOptions.scales.y.title.color = "#FFFFFF";
          this.chartOptions.scales.x.ticks.color = "#D0D2D6";
          this.chartOptions.scales.y.ticks.color = "#D0D2D6";
        } else {
          this.chartOptions.scales.x.title.color = "#5e5873";
          this.chartOptions.scales.y.title.color = "#5e5873";
          this.chartOptions.scales.x.ticks.color = "#6E6B7B";
          this.chartOptions.scales.y.ticks.color = "#6E6B7B";
        }
      },
      immediate: false,
    },
  },
  beforeMount() {
    if (store.state.appConfig.layout.skin == "dark") {
      this.chartOptions.scales.x.title.color = "#FFFFFF";
      this.chartOptions.scales.y.title.color = "#FFFFFF";
      this.chartOptions.scales.x.ticks.color = "#D0D2D6";
      this.chartOptions.scales.y.ticks.color = "#D0D2D6";
    } else {
      this.chartOptions.scales.x.title.color = "#5e5873";
      this.chartOptions.scales.y.title.color = "#5e5873";
      this.chartOptions.scales.x.ticks.color = "#6E6B7B";
      this.chartOptions.scales.y.ticks.color = "#6E6B7B";
    }
    this.getCurrentMonth();
    this.getDiscountReport();
    this.getBillingAccountIds();
  },
  created() {
    if (
      this.userData.default_region_id == "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
    ) {
      const regionCurrencyData = JSON.parse(
        localStorage.getItem("regionCurrencyData")
      );
      for (const [key, value] of Object.entries(
        regionCurrencyData.regions_details
      )) {
        this.currencyOptions = value.currencies;
      }
    }
    this.selectedCurrency = this.userData.deafult_currency_code;
    store.commit("app/CURRENCY_TYPE", this.userData.deafult_currency_code);
  },
  methods: {
    checked(item) {
      if (item.isChecked) {
        for (const [key, value] of Object.entries(
          this.finalChartData.datasets
        )) {
          if (item.name === value.label) {
            this.showClearButton = true;
            this.checkedServices.push(value);
          }
        }
        this.chartData.datasets = this.checkedServices;
      } else {
        if (this.checkedServices.length > 1) {
          for (const [key, value] of Object.entries(this.checkedServices)) {
            if (item.name === value.label) {
              this.checkedServices.splice(key, 1);
            }
          }
        } else {
          this.showClearButton = false;
          this.checkedServices = [];
          this.chartData.datasets = this.finalChartData.datasets;
        }
      }
    },
    onHeadClicked() {
      this.chartData.datasets = this.finalChartData.datasets;
    },
    getCurrentMonth() {
      this.startDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 30);
      this.endDate = new Date();
      this.endDate.setDate(this.endDate.getDate());
      this.dateRange.push(this.startDate);
      this.dateRange.push(this.endDate);
    },
    filterDate() {
      this.isLoading = true;
      if (this.dateRange.length > 0) {
        this.startDate = this.dateRange[0];
        this.endDate = this.dateRange[1];
      }
      if (this.dateRange[0] == null) {
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate());
      }
      this.getDiscountReport();
      this.getBillingAccountIds();
    },
    clear() {
      this.isLoad = true;
      this.projectSelected = "";
      this.dateRange = "";
      this.dateRange = [];
      this.getCurrentMonth();
      this.getDiscountReport();
      this.getBillingAccountIds();
    },
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      let d = new Date(inputFormat),
        month = pad(d.getMonth() + 1),
        day = pad(d.getDate()),
        year = d.getFullYear();
      if (
        this.userData.default_region_id ==
        "0afb05a8-da40-41d9-9e51-e288e6db7789"
      ) {
        return [month, day, year].join("-");
      } else {
        return [day, month, year].join("-");
      }
    },
    convertDateForPassing(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      let d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "-"
      );
    },
    random_hex_color_code() {
      let n = (Math.random() * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    },
    getDiscountReport() {
      this.loading = true;
      let formatedStartDate = this.convertDateForPassing(this.startDate);
      let formatedEndDate = this.convertDateForPassing(this.endDate);
      const pramsObj = {
        global_admin_report:
          this.userData.role_name == "GLOBAL_ADMIN" ||
          this.userData.role_name == "ACCOUNT_MANAGER"
            ? true
            : false,
        currency:
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
            ? this.selectedCurrency
            : "USD",
      };

      if (this.projectSelected.length > 0) {
        pramsObj.project_names = this.projectSelected.toString();
      }

      axios
        .get(
          `/gcp-spend/discount/${this.userData.default_region_id}?start_date=${formatedStartDate}&end_date=${formatedEndDate}`,
          {
            params: pramsObj,
          }
        )
        .then((response) => {
          this.loading = false;
          this.isLoading = false;
          this.isLoad = false;
          this.chartData.datasets = [];
          this.tableItems = [];
          this.totalCost = 0;

          const date = new Date(this.startDate);
          const end = new Date(this.endDate);
          const dates = [];

          while (date <= end) {
            if (date == end) {
              dates.push(new Date(date));
              break;
            } else {
              dates.push(new Date(date));
              date.setDate(date.getDate() + 1);
            }
          }
          this.selectedDates = dates.map((v) => this.convertDate(v));
          this.chartData = {
            ...this.chartData,
            labels: this.selectedDates,
          };

          this.chartData.datasets =
            response.data.discount_type_report.multi_line_data.datasets;
          this.finalChartData.datasets =
            response.data.discount_type_report.multi_line_data.datasets;

          response.data.discount_type_report.table_data.sort(function (a, b) {
            return parseFloat(b.amount) - parseFloat(a.amount);
          });
          this.tableItems = response.data.discount_type_report.table_data;
          this.totalCost = response.data.discount_type_report.total_discount;
        })
        .catch(() => {
          this.isLoading = false;
          this.isLoad = false;
          this.loading = false;
        });
    },
    getBillingAccountIds() {
      this.loading = true;
      const pramsObj = {
        currency:
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
            ? this.selectedCurrency
            : "USD",
      };

      axios
        .get(`/gcp-spend/options/${this.userData.default_region_id}`, {
          params: pramsObj,
        })
        .then((response) => {
          this.loading = false;
          this.projectOptions = [];
          let projects;
          projects = response.data.project_names;
          projects.forEach((element) => {
            if (element !== null) {
              this.projectOptions.push(element);
            }
          });
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.custom-datepicker-filter {
  width: 100%;
}

#gcp-services-table-card {
  margin-bottom: 20px;
}

#gcp-services-table-card-col {
  padding-bottom: 2rem;
}

#circle {
  width: 13px;
  height: 13px;
  border-radius: 7px;
  margin: 3px 12px 0px 14px;
}
</style>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";
#line-chart {
  min-height: 40rem;
  max-height: 50rem;
  height: 100%;
}

.card-height {
  min-height: 400px;
  max-height: 100%;
}

#linear-chart-view {
  padding-bottom: 2rem;
}
#gcp-services > thead > tr > th:nth-child(1) {
  padding: 10.08px 78px;
}
.mx-input {
  height: 37px;
}
</style>
